import './../less/bootstrap.less';
import './../less/SiteFRC.less';
import './../less/spinner.less';
import './../less/EventDropdown.less';
import './../less/boldreports.less';
// only css for bold reports included here
import '@boldreports/javascript-reporting-controls/Content/v2.0/tailwind-light/bold.report-viewer.min.css';

import "bootstrap";
import "tablesorter";

// begin test code
export function myAppTestFunction():string {
    console.log('myAppTestFunction');
    return 'myjunk';
}
export function myAppTestFunctionWithParam(param:string) {
    console.log(`myAppTestFunction with param ${param}`);
}
// end test code